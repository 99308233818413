import React from "react";

const Resources = () => {
  return (
    <div className="bg-blue text-gold h-full w-screen">
      <h1>Resources</h1>
      <h1>Medicaid</h1>
      <h1>Medicare</h1>
      <h1>local</h1>
    </div>
  );
};

export default Resources;
